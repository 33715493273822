import { render, staticRenderFns } from "./bsp-table.vue?vue&type=template&id=6aeeca48&scoped=true&"
import script from "./bsp-table.vue?vue&type=script&lang=js&"
export * from "./bsp-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aeeca48",
  null
  
)

export default component.exports