<template>
  <div ref="ciicTableBox" class="bsp-tableModel" :key ="refData">
    <!--@scroll="showBg"        :class="{scrollBg:scrollX}"-->
    <vxe-table
      auto-resize ="true"
      resizable
      highlight-hover-row
      highlight-current-row
      :max-height="maxHeight"
      v-bind="{...tableAttrs,...$attrs}"
      v-on="$listeners"
      :remote-filter="true"
      remote-sort
      :data="data"
      :treeConfig = "treeConfig"
      :keyboard-config="{isArrow: true}"
      :keep-source="iKeepSource"
      :id="tableId"
      :custom-config="{storage: true}"
      :edit-config="editorConfig"
      :tree-config = "true"
      @filter-change="filterChange"
      @checkbox-change="getSelect"
      @checkbox-all="getSelectAll"
      @sort-change="sortChange"
      @toggle-row-expand="toggleRowExpand"
      class="vxe-table-element scrollBg"
      ref="ciicTable">
      <!--   拖拽属性   resizable-->
      <!--  封装动态表格的-->

      <template v-if="ciicColumns.length > 0">
        <template v-for="ciicColumn in ciicColumns">
          <vxe-table-column
                            v-if="ciicColumn.type"
                            :key="ciicColumn.field"
                            :type="ciicColumn.type"
                            :title="ciicColumn.title"
                            :min-width="ciicColumn.minWidth"
                            :fixed="ciicColumn.fixed?'left':false"
          >
          </vxe-table-column>
          <!--:min-width="ciicColumns.minWidth"-->
          <bsp-table-column v-if="(ciicColumn.refData&&ciicColumn.dataType=='ref')||(ciicColumn.dataType!='ref'&&!ciicColumn.type)" :key="ciicColumn.field" v-bind="{...ciicColumn,...$attrs}" :setid="ciicColumn.setId"
                            :fixed="ciicColumn.fixed?'left':false" sortable :refData.sync="ciicColumn.refData">
            <template slot-scope="scope" v-if="!ciicColumns.grounpColumns && ciicColumn.field">
              <span>{{ scope.row[ciicColumn.field] | bspTableFilter({'1':ciicColumn.dataType,'2':dicts,'3':ciicColumn.refData})}}</span>
            </template>
          </bsp-table-column>
        </template>
      </template>

      <template>
        <slot></slot>
      </template>
      <template v-slot:empty>
          <span>
            <svg class="icon icon-kong" aria-hidden="true">
                  <use xlink:href="#icon-kong"></use>
                </svg>
            <p class="noData">暂无数据</p>
          </span>
        </template>
    </vxe-table>
    <div v-if="showPagination" ref="pagination" class="paginationContain">
      <slot name="pagination">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="ciicDataFilter.page"
                       :page-sizes="[20,50,100,200,500]"
                       :page-size="50"
                       :layout="layout" :total="ciicDataFilter.total">
        </el-pagination>
      </slot>
    </div>
  </div>
</template>

<script>
  import bspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getDataByUrl } from  "@/api/common/common";
  import {mapGetters} from 'vuex';

  export default {
    name: "bspTable",
    props: {
      /**
       * 表格数据筛选对象，用于表格排序，分页
       * sortOrder：排序方式，是否升序,需配合sortField使用（desc降序，asc升序）
       * sortField： 排序字段名称
       * page：当前页码数，使用分页控件时必须有值
       * limit： 每页多少条数据，使用分页控件时必须有值
       * total： 总共有多少条数据，表格获取到数据后应该将返回数据中的total赋给该值
       * filter: 表头过滤条件
       */
      treeConfig:{
        type: Boolean,
        default:false
      },
      ciicDataFilter: {
        type: Object,
        default: () => ({
          page: 1,
          limit: 50,
          total: 0,
        }),
      },
      //动态表格表头
      ciicColumns: {
        type: Array,
        default: () => (
          []
        )
      },
      //动态表格数据
      data: {
        type: Array,
        default: () => (
          []
        )
      },
      // 是否展示分页
      showPagination: {
        type: Boolean,
        default: true,
      },
      // 表格高度是否自动计算占满屏幕
      heightFullScreen: {
        type: Boolean,
        default: true,
      },
      // 可编辑表格的一些配置
      editorConfig: {
        type: Object,
        default: () => (
          {trigger: 'dblclick', mode: 'row'}
        )
      },
      // 编辑表格保持原始值的状态
      iKeepSource: {
        type: Boolean,
        default: false,
      },
      //自定义列配置项 - 启用 localStorage 本地保存功能
      tableId:{
        type: String,
        default: 'ciicTable',
      },
      /*// 引用类型翻译时用的数据
      refData:{
        type: Object,
        default: () => ({
        }),
      },*/
      //  每页显示的条数
      limit: {
        type: Array,
        default: function () {
          return [20, 50, 100]
        },
      },
      //  分页的展示方式
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next',
      },
      maxHeight:{
        type: String,
        default: '',
      }
    },
    data() {
      return {
        refer:0,
        tableHeight: 0,
        // 表格属性
        tableAttrs: {
          // 表格大小
          size: 'medium',
          // 是否高亮选中行
          'highlight-current-row': true,
        },
        //表头筛选的条件
        filtersStore: {},
        //  排序的条件
        sortStore: {},
        //表头信息；
        tableColums: [],
        // scrollX:false,
        // isscroll:0,
      }
    },
    provide() {
      return {
        $filtersStore: this.filtersStore,
        $tableColums: this.tableColums,
      }
    },
    watch: {
      ciicColumns (value,o) {
        this.initRefData(value);
      },
      data:{
        handler(newVal, oldVal) {
          // debugger;
          if(this.$attrs['min-height']){
            let minHeight = this.$attrs['min-height'];
            if (this.$refs.pagination) {
              minHeight -= this.$refs.pagination.getBoundingClientRect().height;
            }
            //判断数据的高度是否高于最小高度,小于280
            if((this.data.length*47+56)>minHeight) {
              if((this.data.length*47+56) > 280){
                this.tableAttrs.height = 280;
              }else{
                this.tableAttrs.height = "";
              }
            }else{
              this.tableAttrs.height = minHeight;
            }
            return false;
          }else{
            // 设置最小高250
            // (this.data.length*47+56)>250 ? "":this.tableAttrs.height = "250"
            if(this.tableAttrs.height < "250"){
              this.tableAttrs.height = "250"
            }
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters([
        'clientHeight',
        'workspaceRect',
        'tableSize',
        'dicts',
      ])
    },
    created() {
      // console.log("this.maxHeight",this.maxHeight)
       console.log("showPagination得值=============",this.showPagination)
    },
    components: {
      bspTableColumn
    },
    mounted(){
      this.$nextTick(function () {
        this.initAttrs()
      })
      this.initEvent()
    },
    methods: {
      /**
       *初始化列表中有引用类型的字段
       * 进行翻译时所需要的数据
       */
       async initRefData(value){
        for(let index in value){
          let item =value[index];
          if(item.dataType=='ref'){
            //判断url中没有没有指定的获取参数
            let url  =item.url;
            if(url){
              let key, name,isAppoint;
              if (url.indexOf("&") > 0) {
                let split = url.split("&");
                url = split[0];
                key = split[1];
                name = split[2];
                isAppoint = true;
              }else {
                isAppoint = false;
              }
              getDataByUrl(url).then(res => {
                let dataMap = res.data.data;
                let retMap = new Object();
                // 判断是不是数组类型 如果是数组类型则转换成化为对象
                if (dataMap instanceof Array) {
                  for (let m in  dataMap) {
                    let keyV = dataMap[m][key];
                    let nameV = dataMap[m][name];
                    retMap[keyV] = nameV;
                  }
                  this.$set(item, 'refData', retMap);
                } else {
                  if(isAppoint){
                    retMap[dataMap[key]]=dataMap[name];
                    this.$set(item, 'refData', dataMap);
                  }else {
                    this.$set(item, 'refData', dataMap);
                  }
                }
              })
            }
          }
        }
      },
      /**
       *  初始化组件属性
       *
       */
      initAttrs() {
        if (this.$attrs.size) {
          this.tableAttrs.size = this.tableSize;
        }
        // 没有指定高度，并且自动全屏，则动态计算表格高度，让工作区中表格的高度不会超出可视区域
        if (!this.$attrs.height && this.heightFullScreen) {
          this.$set(this.tableAttrs, 'height', this.tableHeight);
          this.tableHeight = this.clientHeight - this.$refs.ciicTableBox.getBoundingClientRect().top;
          // 含有分页时计算分页的高度
          console.log("clientHeight",this.clientHeight)
          console.log(this.$refs.ciicTableBox.getBoundingClientRect().top)
          if (this.$refs.pagination) {
            this.tableHeight -= this.$refs.pagination.getBoundingClientRect().height;
          }
          this.tableAttrs.height = this.tableHeight;
          this.$nextTick(function () {
            // 计算表格实际高度
            const workspaceHeight = this.clientHeight - this.workspaceRect.offsetTop;
            // 如果工作区的实际高度大于可视区域中工作区的高度，计算偏移量，保证工作区不超出可视区域
            const offsetHeight = this.workspaceRect.offsetHeight - workspaceHeight;
            if (this.workspaceRect.offsetHeight > workspaceHeight) {
              this.tableHeight -= offsetHeight;
            }
            this.tableAttrs.height = this.tableHeight - 15 - 20;
          })
        }
      },
      /**
       *  初始化组件事件
       *
       */
      initEvent() {

      },
      //  判断行有没有变化，针对单元格编辑的
      hasActiveRow(row) {
        return this.$refs.ciicTable.hasActiveRow(row)
      },
      // 开启keep-source属性后获取更新添加删除记录
      editorRowData(){
          const $table = this.$refs.ciicTable
          const { insertRecords, removeRecords, updateRecords } = $table.getRecordset()
          return updateRecords ;
      },
      // 列头更新后刷新列头
      refreshColumn(){
        this.$refs.ciicTable.refreshColumn();
        console.log(  this.$refs.ciicTable);
      },
      /**
       * 排序列点击事件
       * @param column
       */
      sortChange(column) {
        let sortArray = []
        let obj = {}
        if (column.order) {
          obj.column = column.prop
          if (column.order == 'desc') {
            obj.asc = false;
          } else {
            obj.asc = true;
          }
        } else {
          obj = {}
        }
        sortArray.push(obj)
        this.ciicDataFilter.orders = sortArray;// JSON.stringify(sortArray)
        this.$emit('update:ciic-data-filter', this.ciicDataFilter);
        this.$emit('update:ciicDataFilter', this.ciicDataFilter);
        this.$emit('ciic-data-refresh')
      },
      toggleRowExpand({ row, expanded ,rowIndex}) {
        this.$emit("toggleRowExpand",{ row, expanded ,rowIndex})
      },
      /**
       * 筛选事件
       * @param event
       */
      filterChange(event) {
        let filterArray = []
        this.filtersStore[event.field].value = event.datas

        for (let key in this.filtersStore) {
          let obj = {};
          let value = this.filtersStore[key].value
          if (value &&value.length>0) {
            obj.operator = this.filtersStore[key].operator
            obj.column = key
            obj.value = this.filtersStore[key].value.join(',')
            filterArray.push(obj)
          }

        }
        // 发生表头筛选应该默认从第一页开始查询
        this.ciicDataFilter.page = 1;
        //this.ciicDataFilter.filters=JSON.stringify(filterArray)
        this.ciicDataFilter.filters = filterArray
        this.$emit('update:ciic-data-filter', this.ciicDataFilter)
        this.$emit('ciic-data-refresh')
      },
      /**
       * 改变表格大小
       * @param val
       */
      handleSizeChange(val) {
        this.ciicDataFilter.page = 1;
        this.ciicDataFilter.limit = val
        this.$emit('update:ciic-data-filter', this.ciicDataFilter)
        this.$emit('ciic-data-refresh')
      },
      /**
       * 改变页码数
       * @param val
       */
      handleCurrentChange(val) {
        this.ciicDataFilter.page = val;
        this.$emit('update:ciic-data-filter', this.ciicDataFilter)
        this.$emit('ciic-data-refresh')
      },
      getSelectAll({checked, row}) {
        let selects = this.$refs.ciicTable.getCheckboxRecords();
        this.$emit('ciic-data-select', selects)
      },
      getSelect({checked, row}) {
        var selects = this.$refs.ciicTable.getCheckboxRecords();
        this.$emit('ciic-data-select', selects)
      },
      //横向滚动时，显现固定列的背景
      // showBg(e){
      //   if(e.isX){
      //     if(e.scrollLeft > 0){
      //       this.scrollX = true;
      //     }else{
      //       this.scrollX = false;
      //     }
      //   }
      //
      // }
    }
  }
</script>

<style scoped>

</style>
