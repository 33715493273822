<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <vxe-table-column
    ref="column"
    v-bind="{...tableColumnAttrs,...$attrs}"
    v-on="$listeners"
  >
    <!-- 表头分组-->
    <template v-if="grounpColumns.length > 0">
      <!--     用于表头分组     -->
      <vxe-table-column
        v-for="(grounpColumn,i) in grounpColumns"
        :key="i"
        v-bind="{...grounpColumn}"
        :filter-render="renderFilter(Column, grounpColumn.filterType)"
        :edit-render="renderFilter(Column, grounpColumn.editType)"
      >
        <template v-slot="{ row }">
          <span>{{ row[grounpColumn.field] | bspTableFilter({'1':grounpColumn.dataType,'2':dicts}) }}</span>
        </template>
        <vxe-table-column
          v-for="(Column,index) in grounpColumn.grounpColumns"
          :key="index"
          v-bind="{...Column}"
          :filter-render="renderFilter( Column, Column.filterType)"
          :edit-render="renderFilter(Column, Column.editType)"
        >
          <template v-slot="{ row }">
            <span>{{ row[Column.field] | bspTableFilter({'1':Column.dataType, '2':dicts}) }}</span>
          </template>
        </vxe-table-column>
      </vxe-table-column>
    </template>
    <template v-slot="{ row }">
      <slot :row="row" />
    </template>
    <!--    用于自定义编辑框    -->
    <template
      v-slot:edit="{ row }"
      v-if="slotEdit"
    >
      <slot
        name="edit"
        :row="row"
      />
    </template>
    <!--    用于自定义过滤框    -->
    <template
      v-slot:filters="{ column, context}"
      v-if="slotFilter"
    >
      <slot
        name="filters"
        :row="{ column, context }"
      />
    </template>
  </vxe-table-column>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "BspTableColumn",
        props: {
            //过滤规则
            filterRule: {
                type: String,
                default: 'like'
            },
            //过滤类型，input、select
            filterType: {
                type: String,
                default: ''
            },
            //编辑类型
            editType: {
                type: String,
                default: ''
            },
            //下拉选择的列表
            optionsList: {
                type: Array,
                default: () => (
                    []
                )
            },
            //下拉选择的options选项
            optionProps: {
                type: Object,
                default: () => (
                   	{ value: 'codeItemId', label: 'codeItemName' }
                )
            },
            //下拉选择的setId
            setId: {
                type: String,
                default:""
            },
             // 要移除的 itemId,隔开多个
            remNum: {
                type: String,
                default: ''
            },
            //下拉选择是否可以多选
            multiple: {
                type: Boolean,
                default:false
            },
            //下拉选择是否支持搜索
            filterable: {
                type: Boolean,
                default: true,
            },
            //时间格式
            timeFormat: {
                type: String,
                default:'yyyy-MM-DD'
            },
            //日期选择框的类型
            datePickerType: {
                type: String,
                default:'daterange'
            },
            //分组数据
            grounpColumns:{
                type: Array,
                default: () => (
                    []
                )
            },//引用类型数据
            refData:{
                type: Object,
                default: () => (
                  {}
                )
            },
            //是否需要自定义编辑框
            slotEdit:{
              type: Boolean,
              default:false
            },
            //是否需要自定义过滤框
            slotFilter: {
              type: Boolean,
              default:false
            },
        },
         computed: {
            ...mapGetters([
            'dicts',
            ]),
        },
        inject: ['$filtersStore','$tableColums'],
        created(){
          if(this.filterType == 'select-like') {
            this.filterRule = 'like'
          }

          if(this.filterType == 'select' ||this.filterType == 'dict' ||this.filterType == 'ref') {
            this.filterRule = 'in'
          }
          if(this.filterType == 'datePicker'){
              this.filterRule='between'
          }
          if(this.filterType == 'input' ){
              this.filterRule='like'
           }
          if(this.filterType == 'inputEq' ){
              this.filterRule='='
          }

          if(this.setId &&(this.filterType == 'select' ||this.filterType == 'dict')){
            this.optionsList = this.filterSelctList(this.setId,this.remNum);
          }
          if(this.filterType == 'select-like') {
            this.optionsList = this.filterSelctList(this.setId,this.remNum);
          }
          if(this.refData&&this.filterType == 'ref'){
              this.optionsList = this.filterRefList(this.refData);
          }
          if(this.$attrs.field){
                this.$filtersStore[this.$attrs.field] = {
                        operator: this.filterRule,
                };
                this.$tableColums.push({...this.$attrs}); //获取表格的表头集合

          }else if(this.grounpColumns.length>0){ //表头分组
                let colIndex = 0; //第一级表头的colIndex；
                this.grounpColumns.forEach(function(grounpColumn){ //两级表头
                    if(grounpColumn.grounpColumns){ //三级表头
                        grounpColumn.grounpColumns.forEach(function(column){
                                this.$filtersStore[column.field] = {
                                operator: this.filterRule,
                             };
                             column.colIndex = 1;
                        }.bind(this));
                           grounpColumn.colIndex = grounpColumn.grounpColumns.length;
                    }else{
                        this.$filtersStore[grounpColumn.field] = {
                            operator: this.filterRule,
                        };
                        grounpColumn.colIndex = 1;
                    }
                    colIndex+=grounpColumn.colIndex;
                }.bind(this));
                let grounpColumn = {
                    title:this.$attrs.title,
                    grounpColumns:this.grounpColumns,
                    colIndex,
                };
               this.$tableColums.push(grounpColumn);  //获取表格的表头集合
          }
          this.editTypeHandler()
          this.filterTypeHandler()
          this.$forceUpdate();
        },
      watch:{
        refData:function (newVal,oldVal) {
          console.log("监听引用数据变化",newVal,this.refData);
          this.optionsList = this.filterRefList(this.refData);
          console.log("监听引用数据变化后的下拉值",this.optionsList);

        }
      },
        data(){
            return {
                // 表格列属性
                tableColumnAttrs: {
                },
                selectList:[]
            }
        },
        methods:{
            renderFilter(column, renderType){
              console.log("列表过滤的=============",column)
                if(renderType){
                    if(renderType == 'input'){
                        return {name: 'ElInput', props: {placeholder: '请输入' + column.title}}
                    }else if(renderType == 'select' ||renderType == 'dict'||renderType == 'select-like'){
                        let optionsList = []
                        // select列表过滤
                        optionsList = column.optionList ? column.optionList : this.filterSelctList(column.setId, column.remNum)
                        return {name: 'ElSelect', optionProps: this.optionProps, options: optionsList, props: {multiple: column.multiple,filterable:this.filterable, placeholder: '请选择'}}
                    }else if(renderType == 'ref'){
                        // 引用类型
                        let optionsList = [] ;
                        // select列表过滤
                        optionsList = column.optionList ? column.optionList : this.filterRefList(column.refData);
                        return {name: 'ElSelect',  options: optionsList, props: {multiple: column.multiple,filterable:this.filterable, placeholder: '请选择'}}
                    }else if(renderType == 'datePicker'){
                        return {name: 'ElDatePicker', props: {type: 'daterange', format: "yyyy 年 MM 月 dd 日", valueFormat:"yyyy-MM-dd", rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                    }else if(this.editType == 'inputNumber'){
                        return {name: 'ElInputNumber'}
                    }
                }
            },
          //  判断表头筛选的类型
            filterTypeHandler(){
                if(this.filterType){
                    this.tableColumnAttrs.filters = [{data: ''}]
                    if(this.filterType == 'input' || this.filterType == 'inputEq'){
                        this.tableColumnAttrs.filterRender= {name: 'ElInput', props: {placeholder: '请输入' + this.$attrs.prop,clearable:true}, events: {change: this.enterFilterEvent,clear:this.resetFilterEvent}}
                    }else if(this.filterType == 'select' ||this.filterType == 'dict' || this.filterType == 'select-like'){
                        this.tableColumnAttrs.filterRender={name: 'ElSelect', optionProps: this.optionProps, options: this.optionsList, props: {multiple: this.multiple, filterable:this.filterable,clearable: !this.multiple, placeholder: '请选择'}, events: {change: this.enterFilterEvent,clear:this.resetFilterEvent}}
                    }else if(this.filterType == 'ref'){
                        this.tableColumnAttrs.filterRender={name: 'ElSelect', options: this.optionsList, props: {multiple: this.multiple, clearable: !this.multiple,filterable:this.filterable, placeholder: '请选择'}, events: {change: this.enterFilterEvent,clear:this.resetFilterEvent}}
                    }else if(this.filterType == 'datePicker'){
                        this.tableColumnAttrs.filterRender={name: 'ElDatePicker', props: {type: this.datePickerType,  valueFormat: "yyyy-MM-dd", rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}, events: {change: this.enterFilterEvent}}
                    }
                }
            },
          // 判断编辑类型
            editTypeHandler(){
                if(this.editType){
                    if(this.editType == 'input'){
                        this.tableColumnAttrs.editRender= {name: 'ElInput'}
                    }else if(this.editType == 'inputNumber'){
                        this.tableColumnAttrs.editRender= {name: 'ElInputNumber'}
                    } else if(this.editType == 'select'||this.editType == 'dict' || this.editType == 'select-like'){
                        this.tableColumnAttrs.editRender={name: 'ElSelect', optionProps: this.optionProps, options:this.optionsList, props: {filterable:this.filterable,multiple: this.multiple}}
                    }else if(this.filterType == 'ref'){
                        this.tableColumnAttrs.editRender={name: 'ElSelect', options:this.optionsList, props: {filterable:this.filterable,multiple: this.multiple}}
                    }else if(this.editType == 'datePicker'){
                        this.tableColumnAttrs.editRender={name: 'ElDatePicker', props: {type: 'date',  valueFormat: "yyyy-MM-dd"}}
                    }
                }
            },
            //过滤下拉列表字段
            filterSelctList(setId,remNum){
                if(setId && setId!=''&&this.dicts){
                    return remNum ?
                    this.dicts.codeList[setId].filter((item) => {
                            return remNum.indexOf(item.codeItemId) < 0;
                        }):
                     this.dicts.codeList[setId];
                }else{
                    return this.optionsList
                }
            },
          // 引用类型下拉数据
          filterRefList(refData){
              console.log("引用类型下拉数据加载")
            //判断url中没有没有指定的获取参数
            if(refData){
              let refList = new Array();
              for(let k in refData){
                refList.push({ value: k, label: refData[k] });
              }
              console.log("引用类型下拉数据加载22222",refList)
              return refList;
            }else {
              console.log("引用类型下拉数据加载3333333333333",this.optionsList)
              return this.optionsList;
            }

          },
            //转换下拉框下的字段
            dataTransform(data) {
                if(JSON.stringify(this.fileType)==="{}"){
                    return data
                }
                let _data = [];
                for (let i = 0; i < data.length; i++) {
                    _data[i] = {};
                    _data[i].label = data[i][this.fileType.label];
                    _data[i].value = data[i][this.fileType.value];
                }
                return _data;
            },
            // 回车提交
            enterFilterEvent ({ context, column }, event) {
              context.confirmFilter()
          },
          // 筛选框清空
          resetFilterEvent({ context, column }, event){
            context.resetFilter()
          }
        }
    }
</script>

<style scoped>

</style>
