import request from '@/router/axios';
//const urlPrefix = process.env.VUE_APP_LOCAL_DEVELOPMENT == 'false' ? '' : '/sys'
const urlPrefix = '/sys'
/**
 *  获取动态列头
 */
export function getdynamicTable(obj) {
  return request({
    url: urlPrefix +'/dynamicForm/getDynamicTable',
    method: 'POST',
    data:obj
  });
}

/**
 *  获取动态表单
 */
export function getDynamicForm(obj) {
  return request({
    url: urlPrefix +'/dynamicForm/getDynamicForm',
    method: 'POST',
    data: obj,
  });
}

/**
 *  求和
 */
export function getSum(){
  var array = arguments;
  var lens = array.length,//获取参数的长度
    d = 0,//定义小数位的初始长度，默认为整数，即小数位为0
    sum = 0;//定义sum来接收所有数据的和
  //循环所有的参数
  for(var key in array){//遍历所有的参数
    //把数字转为字符串
    var str = ""+array[key];
    if(str.indexOf(".")!=-1){//判断数字是否为小数
      //获取小数位的长度
      var temp = str.split(".")[1].length;
      //比较此数的小数位与原小数位的长度，取小数位较长的存储到d中
      d = d < temp ? temp : d;
    }
  }
  //计算需要乘的数值
  var m = Math.pow(10,d);
  //遍历所有参数并相加
  for(var key in array){
    sum += array[key]*m;
  }
  //返回结果
  return sum/m;
}

/**
 *  获取动态页签
 */
 export  function  getDynamicTabs(obj) {
  return request({
    url: urlPrefix +'/tabs/tabsList',
    method: 'POST',
    data: obj
  });
}

/**
 * 态表单新增提交保存
 * @param obj 参数
 * @param serv 服务前缀
 * @constructor
 */
export function DynamicFormInsert(obj,serv) {
  return request({
    url: serv +'/dynaForm/ops/insert',
    method: 'POST',
    data: obj,
  });
}
/**
 *  动态表单修改提交保存
 * @param obj 参数
 * @param serv 服务前缀
 * @constructor
 */
export function DynamicFormUpdate(obj,serv) {
  return request({
    url: serv +'/dynaForm/ops/update',
    method: 'PUT',
    data: obj,
  });
}
/**
 *  获取自定义表头
 */
export function getCustomTableHeader(obj) {
  return request({
    url: urlPrefix +'/dynamicForm/customtableheader',
    method: 'post',
    data: obj,
  });
}
/**
 * 导出列表数据 单级表头 excle
 * @param obj 参数
 * @param serv 指定访问服务的前缀
 */
export function exportTable(obj,serv) {
  return request({
    url:serv + '/exceldwnload/exportExcleAll',
    method: 'post',
    data: obj,
    responseType: 'arraybuffer',
  });
}/**
 * 导出列表数据 单级表头 excle
 * @param obj 参数
 * @param serv 指定访问服务的前缀
 */
export function exportSelectTable(obj,serv) {
  return request({
    url:serv + '/exceldwnload/export',
    method: 'post',
    data: obj,
    responseType: 'arraybuffer',
  });
}
// 导出列表表头
/**
 * 导出列表数据 多级表头 excle
 * @param obj 参数
 * @param serv 指定访问服务的前缀
 */
export function exportTableForHeaders(obj,serv) {
  return request({
    url: serv +'/exceldwnload/exportHeaders',
    method: 'POST',
    data: obj,
    responseType: 'arraybuffer',
  });
}

/**
 * 导出列表数据 多级表头 excle
 * @param obj 参数
 * @param serv 指定访问服务的前缀
 */
export function getCodeTreeByCodeSetId(obj) {
  return request({
    url: urlPrefix +'/syscodeitem/getcodetree/'+obj,
    method: 'get',
  });
}

// 下载模板
export function downloadExcleTmpl(obj) {
  return request({
    url: urlPrefix +'/excel/downloadtmpexcle',
    // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}

//雇员下载精简模板
export function downloadEmpExcelTmpl(obj) {
  return request({
    url: urlPrefix +'/excel/downloadtmpexcelemp',
    // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}

// 下载excle上传的错误信息
export function downloadErrorExcle(obj,serv) {
  return request({
    url: serv +'/exceldwnload/downloadError',
    // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}

/**
 * 根据url 得到数据
 * @param obj 参数
 * @param serv 指定访问服务的前缀
 */
export   function getDataByUrl(url,obj) {
  return    request({
    url: url,
    method: 'get',
    params: obj,
  });
}

// 查询客户信息只用修改客户页面信息展示
export function getCusInfoById(obj) {
  return request({
    url: urlPrefix + '/cus/cusinfo',
    method: 'get',
    params: obj,
  });
}
